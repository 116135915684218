// @ts-nocheck
import React from "react";
import moment from "moment";
import { clearCart } from "../../_redux/cart/cartAction";
import { useAppDispatch } from "../../redux/hooks";

const ChefsDates = ({
  menu,
  selectedDate,
  setSelectedDate,
  setCartMenu,
  setErrorLogin,
}: any) => {
  const dispatch = useAppDispatch();

  const monthDates = [...Array(28)].map((_, i) => {
    const d = new Date();
    d.setDate(d.getDate() + i);
    return d;
  });

  const menuDates =
    menu &&
    menu
      ?.map((m: any) => {
        return m.deliveryDays;
      })
      ?.join(",")
      ?.split(",");

  return (
    <div className="mt-5 ml-5 lg:ml-0">
      <div className="">
        <p className="text-2xl font_regular">Pick a delivery date</p>
      </div>

      <div className="flex flex-row mt-6 overflow-scroll mr-10">
        <div
          className={`w-28 h-24 flex flex-col shrink-0 justify-center text-center rounded-xl mr-3 cursor-pointer text-center ${
            selectedDate === "all" ? "activeCalendarBorder" : "calendarBorder"
          }`}
          onClick={() => {
            setSelectedDate("all");
            setCartMenu([]);
            dispatch(clearCart());
            setErrorLogin("");
          }}
        >
          <p className="text-black text-2xl">All</p>
        </div>

        {menuDates?.length > 0 &&
          monthDates?.length > 0 &&
          monthDates
            ?.filter((d) =>
              menuDates.includes(moment(d).format("dd").toUpperCase())
            )
            ?.map((date: any, index: number) => (
              <div
                key={index}
                className={`w-28 h-24 flex flex-col shrink-0 justify-center text-center rounded-xl mr-3 cursor-pointer text-center ${
                  selectedDate !== "all" &&
                  date?.toLocaleDateString() ===
                    selectedDate?.toLocaleDateString()
                    ? "activeCalendarBorder"
                    : "calendarBorder"
                }`}
                onClick={() => {
                  setSelectedDate(date);
                  setCartMenu([]);
                  dispatch(clearCart());
                  setErrorLogin("");
                }}
              >
                <p className="input_text text-sm font_regular uppercase">
                  {moment(date).format("ddd")}
                </p>
                <p className="text-black text-2xl font_regular">
                  {moment(date).format("MMM D")}
                </p>
              </div>
            ))}
      </div>
    </div>
  );
};

export default ChefsDates;
