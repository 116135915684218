import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { useParams } from "react-router-dom";
import Modal from "@mui/material/Modal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { FaConciergeBell } from "react-icons/fa";
import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
  TwitterShareButton,
} from "react-share";
import {
  favoriteAMeal,
  unFavoriteAMeal,
} from "../_redux/favourite/favouriteAction";
import { useAppDispatch } from "../redux/hooks";
import ColoredSpinner from "./ColoredSpinner";
import { formatPrice } from "../utils/formatMethods";

const EventChefShopMenuCard = ({
  chefName,
  menu,
  onClickAddToBag,
  inCart,
  cartMenu,
  handleIncrement,
  handleDecrement,
  handleDelete,
  selectedMealQuantityReached,
  showMinimumQuantityReached,
  addMenuError,
}: any) => {
  const [menuInfoModal, setMenuInfoModal] = useState(false);

  const openMenuInfoModal = () => setMenuInfoModal(true);
  const closeMenuInfoModal = () => {
    setMenuInfoModal(false);
  };

  return (
    <div className="w-full">
      <div
        className="w-full bg-white shadow mt-5 lg:mr-7 rounded-xl cursor-pointer flex flex-col pb-5"
        style={{ height: 440 }}
      >
        <div className="flex-1">
          <div className="">
            {menu?.images && menu?.images?.length > 0 && (
              <Carousel
                showIndicators={false}
                showStatus={false}
                autoPlay
                infiniteLoop
                showThumbs={false}
              >
                {menu?.images?.map((image: string, i: number) => (
                  <img
                    key={i}
                    src={image}
                    alt="food"
                    className="w-full h-52 rounded-t-xl object-cover"
                    onClick={openMenuInfoModal}
                  />
                ))}
              </Carousel>
            )}
          </div>
          <div className="py-4 px-6">
            <h2
              className="text-black font_medium overflow-hidden text-ellipsis capitalize"
              onClick={openMenuInfoModal}
            >
              {menu?.foodName}
            </h2>

            <p
              className="text-gray-400 text-sm font_regular mt-1 truncate"
              onClick={openMenuInfoModal}
            >
              {menu?.description}
            </p>
            <h3
              className="underline text-gray-400 text-sm font_bold mt-1"
              onClick={openMenuInfoModal}
            >
              See more
            </h3>
          </div>
        </div>
        <div className="px-6">
          {inCart ? (
            <button
              className="primary_bg_color text-lg font_medium text-black text-center shadow-sm cursor-pointer rounded-full py-3 w-full"
              onClick={() => handleDelete(menu)}
            >
              <p>Remove from bag</p>
            </button>
          ) : (
            <button
              className="border-2 primary_border_color text-lg font_medium text-gray-400 text-center shadow-sm cursor-pointer rounded-full py-3 w-full"
              onClick={onClickAddToBag}
            >
              Add to bag
            </button>
          )}
        </div>
      </div>

      <Modal
        open={menuInfoModal}
        onClose={closeMenuInfoModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        component={"div"}
      >
        <div className="absolute top-1/2 left-1/2 w-11/12 lg:w-1/3 h-4/5 overflow-scroll -translate-y-1/2 -translate-x-1/2 bg-white rounded-3xl my-10 pb-4 outline-none">
          <div>
            <div
              style={{
                backgroundImage: `url(${menu?.images[0]})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "bottom",
              }}
              className="h-72"
            >
              <div className="flex justify-between py-3 px-6 input_text">
                <div
                  className="rounded-full bg-white flex items-center justify-center w-10 h-10 cursor-pointer"
                  onClick={closeMenuInfoModal}
                >
                  <XMarkIcon className="h-6 w-6" />
                </div>
              </div>
            </div>

            <div className="mt-3 py-3 px-10">
              <h2 className="card_headerText text-2xl font_medium">
                {menu?.foodName}
              </h2>
            </div>

            <div className="flex flex-row justify-between py-3 px-10 grayBackground">
              <div>
                <h1 className="text-lg font_bold">Portion size</h1>
                <div className="mt-1 flex items-center">
                  <FaConciergeBell fontSize={20} color="#717171" />
                  <p className="input_text ml-2">{menu?.portion}</p>
                </div>
              </div>
            </div>

            <div className="py-3 px-10">
              <div className="mb-3">
                <h2 className="text-lg font_bold">Description</h2>
                <p className="text-gray-400 text-sm text-wrap mt-1">
                  {menu?.description}
                </p>
              </div>
              <div className="mb-3">
                <h2 className="text-lg font_bold">Main Ingredients</h2>
                <p className="text-gray-400 text-sm text-wrap mt-1">
                  {menu?.ingredients}
                </p>
              </div>
              <div className="mb-3">
                <h2 className="text-lg font_bold">Other notes</h2>
                <p className="text-gray-400 text-sm text-wrap mt-1">
                  {menu?.note}
                </p>
              </div>

              <div className="mt-7">
                {inCart ? (
                  <button
                    className="primary_bg_color text-lg font_medium text-black text-center shadow-sm cursor-pointer rounded-full py-3 w-full"
                    onClick={onClickAddToBag}
                  >
                    <p>Remove from bag</p>
                  </button>
                ) : (
                  <button
                    className="border-2 primary_border_color text-lg font_medium text-gray-400 text-center shadow-sm cursor-pointer rounded-full py-3 w-full"
                    onClick={onClickAddToBag}
                  >
                    Add to bag
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default EventChefShopMenuCard;
