export const AUTH_ROUTES = {
  auth: "/auth/*",
  chefSignUp: "chef-signup",
  linkChefSignUp: "/auth/chef-signup",
  chefLogin: "chef-login",
  linkChefLogin: "/auth/chef-login",
  chefForgotPassword: "chef-forgot-password",
  linkChefForgotPassword: "/auth/chef-forgot-password",
  chefResetPassword: "chef-reset-password",
  linkChefResetPassword: "/auth/chef-reset-password",

  customerSignUp: "customer-signup",
  linkCustomerSignUp: "/auth/customer-signup/",
  customerLogin: "customer-login/:navigateFrom?/:navigateFrom2?",
  linkCustomerLogin: "/auth/customer-login",
  linkCustomerFromLogin: "/auth/customer-login/:navigateFrom?",
  linkCustomerExploreCheckoutLogin: "/auth/customer-login/explore",
  linkCustomerShopCheckoutLogin: "/auth/customer-login/explore/:businessName",
  customerForgotPassword: "customer-forgot-password",
  linkCustomerForgotPassword: "/auth/customer-forgot-password",
  customerResetPassword: "customer-reset-password",
  linkCustomerResetPassword: "/auth/customer-reset-password",

  companySignUp: "company-signup",
  linkCompanySignUp: "/auth/company-signup",
  companyLogin: "company-login/:navigateFrom?",
  linkCompanyLogin: "/auth/company-login",
  linkCompanyCheckoutLogin: "/auth/company-login/explore",
  companyForgotPassword: "company-forgot-password",
  linkCompanyForgotPassword: "/auth/company-forgot-password",
  companyResetPassword: "company-reset-password",
  linkCompanyResetPassword: "/auth/company-reset-password",
};

export const HOME_ROUTES = {
  explore: "explore",
  linkExplore: "/explore",
  exploreChef: "explore/:businessName/:foodName?",
  linkExploreChef: "/explore/:businessName/:foodName?",
  exploreRestaurant: "restaurant/:businessName/:table",
  linkExploreRestaurant: "/restaurant/:businessName/:table",
  previewChef: "preview/:businessName",
  linkPreviewChef: "/preview/:businessName",
  previewRestaurant: "preview/restaurant/:businessName",
  linkPreviewRestaurant: "/preview/restaurant/:businessName",
  chefLandingPage: "home-chef",
  linkChefLandingPage: "/home-chef",
  privacyPolicy: "privacy-policy",
  linkPrivacyPolicy: "/privacy-policy",
  foodSafety: "food-safety",
  linkFoodSafety: "/food-safety",
  termsService: "terms-service",
  linkTermsService: "/terms-service",
  events: "events/:eventName",
  linkEvents: "/events/:eventName",
  eventRegister: "events/register/:eventName",
  linkEventRegister: "/events/register/:eventName",
  subscription: "subscription",
  linkSubscription: "/subscription",
  companies: "companies",
  linkCompanies: "/companies",
};

export const CUSTOMER_ROUTES = {
  customer: "customer/*",
  customerDashboard: "customer/dashboard",
  linkCustomer: "/customer",

  customerWallet: "wallet",
  linkCustomerWallet: "/customer/wallet",
  customerOrders: "orders",
  linkCustomerOrders: "/customer/orders",
  customerFavourites: "favourites",
  linkCustomerFavourites: "/customer/favourites",
  customerChat: "chat",
  linkCustomerChat: "/customer/chat",
  customerSettings: "settings",
  linkCustomerSettings: "/customer/settings",
  customerSubscription: "subscription",
  linkCustomerSubscription: "/customer/subscription",
};

export const CHEF_ROUTES = {
  chef: "/chef/*",
  linkChef: "/chef",
  chefOrders: "orders",
  linkChefOrders: "/chef/orders",
  chefDineIn: "dine-in",
  linkChefDineIn: "/chef/dine-in",
  chefTableManagement: "dine-in/tables",
  linkChefTableManagement: "/chef/dine-in/tables",
  chefSectionManagement: "dine-in/section",
  linkChefSectionManagement: "/chef/dine-in/section",
  kitchen: "kitchen",
  linkKitchen: "/chef/kitchen",
  kitchenMenu: "kitchen-menu",
  linkKitchenMenu: "/chef/kitchen-menu",
  restaurantDineIn: "restaurant/dine-in",
  linkRestaurantDineIn: "/chef/restaurant/dine-in",
  chefMenu: "menu",
  linkChefMenu: "/chef/menu",
  chefMenuOnline: "menu/online",
  linkChefMenuOnline: "/chef/menu/online",
  chefMenuDineIn: "menu/dine-in",
  linkChefMenuDineIn: "/chef/menu/dine-in",
  chefWallet: "wallet",
  linkChefWallet: "/chef/wallet",
  chefChat: "chat",
  linkChefChat: "/chef/chat",
  chefSettings: "settings",
  linkChefSettings: "/chef/settings",
  chefLandingPage: "chef-explore",
  linkChefLandingPage: "/chef-explore",
  subChefs: "sub-chef",
  linkSubChefs: "/chef/sub-chef",
  chefReports: "reports",
  linkChefReports: "/chef/reports",
};

export const SUB_CHEF_ROUTES = {
  subChef: "/sub-chef/*",
  linkSubChef: "/sub-chef",
  subChefDineIn: "dine-in",
  linkSubChefDineIn: "/sub-chef/dine-in",
  subChefTableManagement: "tables",
  linkSubChefTableManagement: "/sub-chef/tables",
  subChefMenu: "menu",
  linkSubChefMenu: "/sub-chef/menu",
  subChefMenuOnline: "menu/online",
  linkSubChefMenuOnline: "/sub-chef/menu/online",
  subChefMenuDineIn: "menu/dine-in",
  linkSubChefMenuDineIn: "/sub-chef/menu/dine-in",
  kitchen: "kitchen",
  linkKitchen: "/sub-chef/kitchen",
  kitchenMenu: "kitchen-menu",
  linkKitchenMenu: "/sub-chef/kitchen-menu",
  subChefSettings: "settings",
  linkSubChefSettings: "/sub-chef/settings",
};

export const WAITER_ROUTES = {
  waiter: "/waiter/*",
  linkWaiter: "/waiter",
};

export const COMPANY_ROUTES = {
  company: "/company/*",
  linkCompany: "/company",
  companyEmployees: "employees",
  linkCompanyEmployees: "/company/employees",
  companyWallet: "wallet",
  linkCompanyWallet: "/company/wallet",
  companySettings: "settings",
  linkCompanySettings: "/company/settings",
  // companyLandingPage: "company-home",
  // linkCompanyLandingPage: "/company-home",
};
