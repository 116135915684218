import React, { useEffect } from "react";
import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import Hotjar from "@hotjar/browser";
import AppRoutes from "./router";

// declare global {
//   interface Window {
//     dataLayer: any[];
//   }
// }

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID || "",
};

function App() {
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID || "e2");
    ReactGA.send({
      hitType: "pageview",
      page: window.location.pathname + window.location.search,
    });
    TagManager.initialize(tagManagerArgs);
    Hotjar.init(
      Number(process.env.REACT_APP_HOTJAR_SITE_ID),
      Number(process.env.REACT_APP_HOTJAR_VERSION)
    );
  }, []);

  // window.dataLayer.push({
  //   event: "pageview",
  //   page: {
  //     url:
  //       window.location.origin +
  //       window.location.pathname +
  //       window.location.search,
  //     title: window.location.pathname,
  //   },
  // });

  return <AppRoutes />;
}

export default App;
