import { Routes, Route } from "react-router-dom";
import ChefSignUp from "../chef/SignUp";
import ChefLogin from "../chef/Login";
import ChefForgotPassword from "../chef/ForgotPassword";
import ChefResetPassword from "../chef/ResetPassword";

// import ChefForgotPassword from "../chef/ForgotPassword";
import CustomerSignUp from "../customer/SignUp";
import CustomerLogin from "../customer/Login";
import CustomerForgotPassword from "../customer/ForgotPassword";
import CustomerResetPassword from "../customer/ResetPassword";

import CompanySignUp from "../company/SignUp";
import CompanyLogin from "../company/Login";
import CompanyForgotPassword from "../company/CompanyForgotPassword";
import CompanyResetPassword from "../company/ResetPassword";

import { AUTH_ROUTES } from "../router/routes";

const AuthRoutes = () => (
  <Routes>
    <Route>
      {/* Customer Routes */}
      <Route index element={<CustomerSignUp />} />
      <Route path={AUTH_ROUTES.customerSignUp} element={<CustomerSignUp />} />
      <Route path={AUTH_ROUTES.customerLogin} element={<CustomerLogin />} />
      <Route
        path={AUTH_ROUTES.customerForgotPassword}
        element={<CustomerForgotPassword />}
      />
      <Route
        path={AUTH_ROUTES.customerResetPassword}
        element={<CustomerResetPassword />}
      />

      {/* Chef Routes */}
      <Route path={AUTH_ROUTES.chefSignUp} element={<ChefSignUp />} />
      <Route path={AUTH_ROUTES.chefLogin} element={<ChefLogin />} />
      <Route
        path={AUTH_ROUTES.chefForgotPassword}
        element={<ChefForgotPassword />}
      />
      <Route
        path={AUTH_ROUTES.chefResetPassword}
        element={<ChefResetPassword />}
      />
      <Route path={AUTH_ROUTES.chefSignUp} element={<ChefSignUp />} />
      <Route path={AUTH_ROUTES.chefLogin} element={<ChefLogin />} />
      <Route
        path={AUTH_ROUTES.chefForgotPassword}
        element={<ChefForgotPassword />}
      />

      {/* WAITER */}
      <Route path={AUTH_ROUTES.chefLogin} element={<ChefLogin />} />
      
      {/* COMPANY */}
      <Route path={AUTH_ROUTES.companySignUp} element={<CompanySignUp />} />
      <Route path={AUTH_ROUTES.companyLogin} element={<CompanyLogin />} />
      <Route
        path={AUTH_ROUTES.companyForgotPassword}
        element={<CompanyForgotPassword />}
      />
      <Route
        path={AUTH_ROUTES.companyResetPassword}
        element={<CompanyResetPassword />}
      />

    </Route>
  </Routes>
);

export default AuthRoutes;
